import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const applyJobSlice = createSlice({
  name: "applyJob",
  initialState,
  reducers: {
    setApplyJobData: (state, action) => {
      state.setApplyJobData = action.payload;
    },
    setReadApplyJobData: (state, action) => {
      state.setReadApplyJobData = action.payload;
    },
    setUnReadApplyJobData: (state, action) => {
      state.setUnReadApplyJobData = action.payload;
    },
  },
});

export const { setApplyJobData, setUnReadApplyJobData, setReadApplyJobData } =
  applyJobSlice.actions;

export default applyJobSlice.reducer;

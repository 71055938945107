import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const newsMediaSlice = createSlice({
  name: "newsMedia",
  initialState,
  reducers: {
    setNewsMediaData: (state, action) => {
      state.setNewsMediaData = action.payload;
    },
    setNewsMediaOptions: (state, action) => {
      state.setNewsMediaOptions = action.payload;
    },
  },
});

export const { setNewsMediaData, setNewsMediaOptions } =
newsMediaSlice.actions;

export default newsMediaSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const whitePaperSlice = createSlice({
  name: "whitePaper",
  initialState,
  reducers: {
    setWhitePaperData: (state, action) => {
      state.setWhitePaperData = action.payload;
    },
    setWhitePaperOptions: (state, action) => {
      state.setWhitePaperOptions = action.payload;
    },
  },
});

export const { setWhitePaperData, setWhitePaperOptions } = whitePaperSlice.actions;

export default whitePaperSlice.reducer;

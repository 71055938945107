import React, { forwardRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'



const RichTextEditor = forwardRef((props, ref) => {
	
	
	  return (
		<div className="rich-text-editor">
		  <ReactQuill
			ref={ref}
			{...props}
			className='text-inherit dark:text-white'
		  />
		</div>
	  );
})

export default RichTextEditor

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const ourClientsSlice = createSlice({
  name: "ourClients",
  initialState,
  reducers: {
    setOurClientsData: (state, action) => {
      state.setOurClientsData = action.payload;
    },
    setOurClientsOptions: (state, action) => {
      state.setOurClientsOptions = action.payload;
    },
  },
});

export const { setOurClientsData, setOurClientsOptions } = ourClientsSlice.actions;

export default ourClientsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const clientLogosSlice = createSlice({
  name: "clientLogos",
  initialState,
  reducers: {
    setClientLogosData: (state, action) => {
      state.setClientLogosData = action.payload;
    },
    
  },
});

export const { setClientLogosData } =clientLogosSlice.actions;

export default clientLogosSlice.reducer;

import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import counters from "./counters";
import config from "./config";
import homeSlider from "./homeSlider"
import aboutUs from "./aboutUs"
import clientLogos from "./clientLogos";
import cultureImages from "./cultureImages";
import openpositions from "./openpositions";
import contacts from "./contacts";
import newsMedia from "./newsMedia"
import ourMission from "./ourMission";
import whitePaper from "./whitePaper";
import applyJob from "./applyJob";
import footer from "./footer";
import ourClients from "./ourClients"

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    counters,
    config,
    homeSlider,
    aboutUs,
    clientLogos,
    cultureImages,
    openpositions,
    ourMission,
    contacts,
    newsMedia,
    whitePaper,
    applyJob,
    footer,
    ourClients,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;

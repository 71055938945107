import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const homeSliderSlice = createSlice({
  name: "homeSlider",
  initialState,
  reducers: {
    setHomeSliderData: (state, action) => {
      state.setHomeSliderData = action.payload;
    },
    setHomeSliderOptions: (state, action) => {
      state.setHomeSliderOptions = action.payload;
    },
  },
});

export const { setHomeSliderData, setHomeSliderOptions } =
homeSliderSlice.actions;

export default homeSliderSlice.reducer;
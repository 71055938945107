import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {
    setAboutUsData: (state, action) => {
      state.setAboutUsData = action.payload;
    },
    setAboutUsOptions: (state, action) => {
      state.setAboutUsOptions = action.payload;
    },
  },
});

export const { setAboutUsData, setAboutUsOptions } = aboutUsSlice.actions;

export default aboutUsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const ourMissionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    setOurMissionData: (state, action) => {
      state.setOurMissionData = action.payload;
    },
    setOurMissionOptions: (state, action) => {
      state.setOurMissionOptions = action.payload;
    },
  },
});

export const { setOurMissionData, setOurMissionOptions } = ourMissionSlice.actions;

export default ourMissionSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const cultureImageSlice = createSlice({
  name: "cultureImages",
  initialState,
  reducers: {
    setCultureImagesData: (state, action) => {
      state.setCultureImagesData = action.payload;
    },
    setCultureImagesOptions: (state, action) => {
      state.setCultureImagesOptions = action.payload;
    },
  },
});

export const { setCultureImagesData, setCultureImagesOptions } =
cultureImageSlice.actions;

export default cultureImageSlice.reducer;